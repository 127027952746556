export const environment = {
  production: 'false',
  firebase: {
    apiKey: 'AIzaSyBdcHt8P0DBJjkpoq6r_Tq3oh7z8MPv9DE',
    authDomain: 'kiwiz-dev.firebaseapp.com',
    projectId: 'kiwiz-dev',
    storageBucket: 'kiwiz-dev.appspot.com',
    messagingSenderId: '11546753971',
    appId: '1:11546753971:web:5cd1a67bb4be82da2529c6',
    measurementId: 'G-94YRMF722L',
  },
  apiUrl: 'https://staging.api.carinspect.com/v1.0',
  url: 'https://staging.app.carinspect.com',
  application_token: 'ff3e771c-eb08-11ea-9366-5076afa7169c',
  gtm: 'undefined',
  stripe_key: 'pk_test_51ITpicBy4wlBwrL5LzIuKZCq35xqDtcJPPRG7KJJMgtIQ44uwCzYPyCcTQ26ebsxyVS7AlHP9B8MIRlOk2Xm5X8J00osRuF8ta',
  google_maps_key: 'AIzaSyBBRduREYy8aYVshvLLRjKlqJ8Bez22LxU',
  reportPSIUrl: 'http://staging.app.carinspect.com/client/psiInspection/',
  reportUrl: 'http://staging.app.carinspect.com/client/inspection/'
};
